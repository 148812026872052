const setEqualHeight = ($elems) => {
    let outerHeight = 0;
    $elems.removeAttr('style');
    $elems.each((index, elem) => {
        const elemHeight = $(elem).outerHeight(false);

        outerHeight = elemHeight > outerHeight ? elemHeight : outerHeight;
    });

    $elems.outerHeight(outerHeight);
};

export default setEqualHeight;
