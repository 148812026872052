import initSlickSlider from '../utils/initSlickSlider';

const selectors = {
    slider: '.js-portfolio-thumb-slider',
    fullSlider: '.js-portfolio-full-slider',
    slide: '.js-portfolio-thumb-slider-slide',
    sliderWrapper: '.js-portfolio-thumb-slider-wrapper',
    prevArrow: '.js-portfolio-thumb-slider-prev-btn',
    nextArrow: '.js-portfolio-thumb-slider-next-btn'
};

const defaultOptions = {
    mobileFirst: true,
    rows: 0,
    initialSlide: 0,
    slidesToShow: 3,
    infinite: false,
    dots: false,
    edgeFriction: 0.12,
    touchThreshold: 15,
    arrows: false,
    swipe: true,
    variableWidth: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 459,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 549,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 635,
            settings: {
                slidesToShow: 7
            }
        },
        {
            breakpoint: 725,
            settings: {
                slidesToShow: 8
            }
        },
        {
            breakpoint: 813,
            settings: {
                slidesToShow: 9
            }
        },
        {
            breakpoint: 879,
            settings: {
                arrows: true,
                vertical: true,
                variableWidth: false,
                slidesToShow: 4,
                swipe: false
            }
        }
    ]
};

const init = ($modal) => {
    const $slider = $modal.find(selectors.slider);

    if (!$slider.length) {
        return;
    }

    const prevArrow = $slider.closest(selectors.sliderWrapper).find(selectors.prevArrow);
    const nextArrow = $slider.closest(selectors.sliderWrapper).find(selectors.nextArrow);
    const asNavFor = `#${$modal.attr('id')} ${selectors.fullSlider}`;
    const options = $.extend({}, defaultOptions, {
        prevArrow,
        nextArrow,
        asNavFor
    });

    initSlickSlider($slider, options);
};

const destroy = ($modal) => {
    const $slider = $modal.find(selectors.slider);

    if (!$slider.length) {
        return;
    }

    $slider.slick('unslick');
};

const portfolioThumbSlider = {
    init,
    destroy
};

export default portfolioThumbSlider;
