import portfolioFullSlider from '../modules/portfolio-full-slider';
import portfolioThumbSlider from '../modules/portfolio-thumb-slider';

const selectors = {
    modal: '.js-universal-modal'
};

const onModalOpen = (evt) => {
    const $modal = $(evt.currentTarget);
    portfolioFullSlider.init($modal);
    portfolioThumbSlider.init($modal);
};

const onModalClose = (evt) => {
    const $modal = $(evt.currentTarget);
    portfolioFullSlider.destroy($modal);
    portfolioThumbSlider.destroy($modal);
};

const portfolioModalSlider = () => {
    const $modals = $(selectors.modal);

    if (!$modals.length) {
        return;
    }

    $modals.on('universal-modal:open', onModalOpen).on('universal-modal:close', onModalClose);
};

export default portfolioModalSlider;
