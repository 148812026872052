const universalModal = () => {
    const $body = $('body');
    const $document = $(document);
    const ESC_KEYCODE = 27;

    const selectors = {
        elem: '.js-universal-modal',
        btn: '.js-universal-modal-btn',
        overlay: '.js-universal-modal-overlay',
        closeBtn: '.js-universal-modal-close-btn'
    };

    const states = {
        ovHidden: 'ov-hidden',
        isActive: 'is-active'
    };

    function escHandler(evt) {
        if (evt.keyCode !== ESC_KEYCODE) {
            return;
        }

        closeModal(); // eslint-disable-line
    }

    function closeModal() {
        const $elem = $(selectors.elem).filter(`.${states.isActive}`);

        $elem.removeClass(states.isActive);

        $body.removeClass(states.ovHidden);

        $document.off('keyup', escHandler);

        $elem.trigger('universal-modal:close');
    }

    function openModal(evt) {
        const $this = $(evt.currentTarget);
        const modalId = $this.data('modal');
        const $modal = $(modalId);

        if (!$modal.length) {
            return;
        }

        evt.preventDefault();

        $document.on('keyup', escHandler);

        $modal.addClass(states.isActive);

        $body.addClass(states.ovHidden);

        $modal.trigger('universal-modal:open');
    }

    $document
        .on('click', selectors.btn, openModal)
        .on('click', selectors.overlay, closeModal)
        .on('click', selectors.closeBtn, closeModal);
};

export default universalModal;
