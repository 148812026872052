const blockBody = () => {
    const body = document.body;
    const $html = $('html');
    const blockingClass = 'ov-hidden';

    return {
        block() {
            body.classList.add(blockingClass);
            $html.addClass(blockingClass);
        },
        unblock() {
            body.classList.remove(blockingClass);
            $html.removeClass(blockingClass);
        }
    };
};

export default blockBody;
