import blockBody from '../utils/blockBody';
import shouldManageBodyStyle from '../utils/should-manage-body-style';

const selectors = {
    btn: '.js-mobile-menu-toggle',
    menu: '.js-mobile-menu',
    overlay: '.js-mobile-menu-overlay'
};

const states = {
    active: 'is-active',
    hidden: 'ov-hidden'
};

const toggleMobileMenu = () => {
    const $toggleMenuBtn = $(selectors.btn);
    const $mobileMenu = $(selectors.menu);
    const $overlay = $(selectors.overlay);

    if (!$toggleMenuBtn.length) {
        return;
    }

    let shouldBodyBeControlled = true; // the variable will help to decide, whether we should delete the 'ov-hidden' class from body after closing the popup or not (if another popup is opened under it, we shouldn't do it)

    function toggleMenu() {
        $toggleMenuBtn.toggleClass(states.active);
        $mobileMenu.toggleClass(states.active);
        $overlay.toggleClass(states.active);
        shouldBodyBeControlled = shouldManageBodyStyle();

        if (shouldBodyBeControlled) {
            blockBody().block();
        } else {
            blockBody().unblock();
        }
    }

    function closeMenu() {
        $toggleMenuBtn.toggleClass(states.active);
        $mobileMenu.removeClass(states.active);
        $overlay.removeClass(states.active);

        if (shouldBodyBeControlled) {
            blockBody().unblock();
        }
    }

    $toggleMenuBtn.on('click', () => {
        toggleMenu();
    });

    $overlay.on('click', () => {
        closeMenu();
    });
};

export default toggleMobileMenu;
