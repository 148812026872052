import initSlickSlider from '../utils/initSlickSlider';

const selectors = {
    slider: '.js-portfolio-full-slider',
    slide: '.js-portfolio-full-slider-slide',
    thumbSlider: '.js-portfolio-thumb-slider'
};

const defaultOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: selectors.thumbSlider
};

const init = ($modal) => {
    const $slider = $modal.find(selectors.slider);

    if (!$slider.length) {
        return;
    }

    const asNavFor = `#${$modal.attr('id')} ${selectors.thumbSlider}`;
    const options = $.extend({}, defaultOptions, {
        asNavFor
    });

    initSlickSlider($slider, options);
};

const destroy = ($modal) => {
    const $slider = $modal.find(selectors.slider);

    if (!$slider.length) {
        return;
    }

    $slider.slick('unslick');
};

const portfolioFullSlider = {
    init,
    destroy
};

export default portfolioFullSlider;
