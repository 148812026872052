import initSlickSlider from '../utils/initSlickSlider';
import setEqualHeight from '../utils/setEqualHeight';

const selectors = {
    slider: '.js-horizontal-slider',
    slide: '.js-horizontal-slider-slide',
    sliderWrapper: '.js-horizontal-slider-wrapper',
    prevArrow: '.js-horizontal-slider-prev-btn',
    nextArrow: '.js-horizontal-slider-next-btn'
};

const MIN_SLIDER_LENGTH = 1;

const defaultOptions = {
    mobileFirst: true,
    rows: 0,
    initialSlide: 0,
    slidesToShow: 1,
    infinite: false,
    dots: false,
    edgeFriction: 0.12,
    touchThreshold: 15,
    arrows: false,
    swipe: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 519,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 839,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1116,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 1279,
            settings: {
                arrows: true,
                slidesToShow: 3
            }
        }
    ]
};

const setDelayedEqualHeight = ($slider) => () => {
    setTimeout(() => {
        const $slides = $slider.find(selectors.slide);
        setEqualHeight($slides);
    }, 0); // a zero delay to let the slider finish its calculations
};

const horizontalSlider = () => {
    const $sliders = $(selectors.slider);

    if ($sliders.length < MIN_SLIDER_LENGTH) {
        return;
    }

    $sliders.each((index, slider) => {
        const $slider = $(slider);
        const prevArrow = $slider.closest(selectors.sliderWrapper).find(selectors.prevArrow);
        const nextArrow = $slider.closest(selectors.sliderWrapper).find(selectors.nextArrow);
        const options = $.extend({}, defaultOptions, {
            prevArrow,
            nextArrow
        });

        const setSlidesEqualHeight = setDelayedEqualHeight($slider);

        $slider.on('init', setSlidesEqualHeight);
        $slider.on('breakpoint', setSlidesEqualHeight);

        initSlickSlider($slider, options);
    });
};

export default horizontalSlider;
