import '@babel/polyfill';
import 'classlist-polyfill';
import '../vendors/focus-visible.min';
import objectFitImages from 'object-fit-images';
import toggleMobileMenu from '../modules/toggle-mobile-menu';
import anchorScrolling from '../modules/anchor-scrolling';
import horizontalSlider from '../modules/horizontal-slider';
import fancyboxGallery from '../modules/fancybox-gallery';
import universalModal from '../modules/universal-modal';
import portfolioModalSlider from '../modules/portfolio-modal-slider';

$(() => {
    objectFitImages();
    toggleMobileMenu();
    anchorScrolling();
    horizontalSlider();
    fancyboxGallery();
    universalModal();
    portfolioModalSlider();
});
