const SCROLL_OFFSET = 30;
const selectors = {
    html: 'html',
    body: 'body',
    mobileMenuOverlay: '.js-mobile-menu-overlay',
    mobileMenu: '.js-mobile-menu',
    mobileMenuBtn: '.js-mobile-menu-toggle',
    anchorLink: '.js-anchor-link'
};

const states = {
    active: 'is-active',
    hidden: 'ov-hidden'
};

const closeMenu = ($html) => {
    $html.removeClass(states.hidden);
    $(selectors.body).removeClass(states.hidden);
    $(selectors.mobileMenuOverlay).removeClass(states.active);
    $(selectors.mobileMenu).removeClass(states.active);
    $(selectors.mobileMenuBtn).removeClass(states.active);
};

const anchorScrolling = () => {
    const $link = $(selectors.anchorLink);

    if ($link.length < 1) {
        return;
    }

    $link.each((index, item) => {
        const $item = $(item);

        $item.on('click', function (event) {
            event.preventDefault();
            const $html = $(selectors.html);
            const $this = $(event.currentTarget);
            const id = $this.attr('href');
            const $target = $(`${id}`);

            if ($html.hasClass(states.hidden)) {
                closeMenu($html);
            }

            $('html, body').animate(
                {
                    scrollTop: $target.offset().top - SCROLL_OFFSET
                },
                500
            );
        });
    });
};

export default anchorScrolling;
